<template>
  <div class="sites-menu" :class="{'is-mobile': isMobile, expanded: isExpanded}">
    <sites-menu-item
      :site="familyWebsiteDetailsState"
      :is-current="true"
      @click.native.stop="toggleExpanded"
      :display-name="familyWebsiteDetailsState.display_name"
    ></sites-menu-item>
    <sites-menu-item
      v-for="site of otherSitesMenuData"
      :key="site.name"
      :site="site"
      :display-name="site.display_name"
    ></sites-menu-item>
    <sites-menu-item :route-to="familyTreeRoute" display-name="Family Tree"></sites-menu-item>
    <sites-menu-item :route-to="homeRoute" display-name="Back to Main Website"></sites-menu-item>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import SitesMenuItem from '@/components/modules/familyWebsite/templates/SitesMenuItem';

export default {
  components: {SitesMenuItem},
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(['familyWebsiteDetailsState', 'familyWebsiteListState']),
    otherSitesMenuData() {
      return this.familyWebsiteListState
        ? this.familyWebsiteListState.filter(site => site.name !== this.familyWebsiteDetailsState.name)
        : [];
    },
    familyTreeRoute() {
      if (this.familyWebsiteDetailsState.is_owned) {
        return {name: 'familytree-details-my'};
      }
      return {name: 'familytree-details', params: {id: this.familyWebsiteDetailsState.family_tree_id}};
    },
    homeRoute() {
      return {name: 'main-page'};
    },
  },
  methods: {
    toggleExpanded() {
      if (this.isMobile) {
        this.isExpanded = !this.isExpanded;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sites-menu {
  position: absolute;
  font-weight: bold;
  width: 100%;
  max-height: var(--report-menu-sites-closed-height);
  overflow: hidden;
  transition: max-height 0.5s ease;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &:not(.is-mobile):hover,
  &.is-mobile.expanded {
    overflow-y: scroll;
    max-height: 100vh;

    .current::v-deep {
      span {
        white-space: normal;
      }
    }
  }

  .menu-item:last-child {
    box-shadow: 0 14px 20px -20px rgba(0, 0, 0, 0.6);
  }
}
</style>
