<template>
  <router-link class="menu-item" :class="{current: isCurrent}" :to="siteRouteTo">
    <span>{{ displayName }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    site: {type: Object, required: false},
    routeTo: {type: Object, required: false},
    isCurrent: {type: Boolean, default: false},
    displayName: {type: String},
  },
  computed: {
    siteRouteTo() {
      if (this.routeTo) {
        return this.routeTo;
      }
      if (this.isCurrent) {
        return this.$route;
      }
      const routeName = this.$route.meta && this.$route.meta.isJLTReport ? 'jlt-report-site-name' : 'fws-site-name';
      return {name: routeName, params: {username: this.site.username, sitename: this.site.name}};
    },
  },
  name: 'SitesMenuItem',
};
</script>

<style lang="scss" scoped>
a.menu-item {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  padding: 15px;
  min-height: 50px;
  border-bottom: 1px solid var(--report-menu-sites-border);
  background-color: var(--report-menu-sites-background);
  font-family: var(--report-accent-font);
  word-break: break-word;

  font-size: 24px;
  @media only screen and (max-width: $breakpoint-mobile) {
    font-size: 21px;
  }

  &:hover {
    text-decoration: none;
    background-color: var(--report-menu-sites-background-hover);
  }

  &.current {
    padding-right: 30px;
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 10px);
    }

    &::after {
      position: absolute;
      right: 17px;
      display: flex;
      align-items: center;
      content: '▾';
    }
  }
}
</style>
